import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit';
import Button, { ButtonProps } from '../../elements/Button';
import { useTranslations } from 'use-intl';
import { ReactNode } from 'react';

const ConnectWrapper = ({
  children,
  mounted,
}: {
  children: ReactNode;
  mounted: boolean;
}) => {
  if (mounted) {
    return children;
  }
  return (
    <div
      aria-hidden={true}
      style={{ opacity: 0, pointerEvents: 'none', userSelect: 'none' }}
    >
      {children}
    </div>
  );
};

const ConnectButton = ({
  className,
  children,
  connectedRender,
}: Partial<Pick<ButtonProps, 'className'>> & {
  children?: ReactNode;
  connectedRender?: ReactNode;
}) => {
  const t = useTranslations('Connect');

  return (
    <RainbowConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        return (
          <ConnectWrapper mounted={mounted}>
            <div
              {...(!mounted && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!mounted || !account || !chain) {
                  return (
                    <Button onClick={openConnectModal} className={className}>
                      {children || t('connect')}
                    </Button>
                  );
                }
                if (chain.unsupported) {
                  return (
                    <Button
                      onClick={openChainModal}
                      className={className}
                      variant="tertiary"
                    >
                      {children || t('changeNetwork')}
                    </Button>
                  );
                }
                if (connectedRender) {
                  return connectedRender;
                }

                return (
                  <Button onClick={openAccountModal} className={className}>
                    {children || t('user', { username: account.displayName })}
                  </Button>
                );
              })()}
            </div>
          </ConnectWrapper>
        );
      }}
    </RainbowConnectButton.Custom>
  );
};

export default ConnectButton;
